<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="licenses"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Лицензии</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.licenseeName="{item}">
      <div>{{ item.licenseeName }}</div>
    </template>

    <template #item.licensee="{item}">
      <template v-if="item.licensee.accountId">
        <strong>Аккаунт:</strong> {{ item.licensee.accountId }}
      </template>

      <template v-if="item.licensee.companyId">
        <strong>Компания:</strong> {{ item.licensee.companyId }}
      </template>
    </template>

    <template #item.active="{item}">
      <VIcon :color="item.active ? 'success' : 'warning'">
        fal {{ item.active ? 'fa-check-circle' : 'fa-times-circle' }}
      </VIcon>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'LicensesTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    licenses: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Приложение',
          align: 'left',
          sortable: true,
          value: 'appName',
        },
        {
          text: 'Название компании/аккаунта',
          align: 'left',
          sortable: false,
          value: 'licenseeName',
        },
        {
          text: 'Лицензия',
          align: 'left',
          sortable: false,
          value: 'licensee',
        },
        {
          text: 'Активная',
          align: 'left',
          sortable: true,
          value: 'active',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
