var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.licenses,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VToolbar',{attrs:{"flat":""}},[_c('VToolbarTitle',[_vm._v("Лицензии")]),_c('VSpacer'),_vm._t("topActions")],2)]},proxy:true},{key:"item.licenseeName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.licenseeName))])]}},{key:"item.licensee",fn:function(ref){
var item = ref.item;
return [(item.licensee.accountId)?[_c('strong',[_vm._v("Аккаунт:")]),_vm._v(" "+_vm._s(item.licensee.accountId)+" ")]:_vm._e(),(item.licensee.companyId)?[_c('strong',[_vm._v("Компания:")]),_vm._v(" "+_vm._s(item.licensee.companyId)+" ")]:_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('VIcon',{attrs:{"color":item.active ? 'success' : 'warning'}},[_vm._v(" fal "+_vm._s(item.active ? 'fa-check-circle' : 'fa-times-circle')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("rowActions",null,{"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }