<template>
  <TTView>
    <VRow>
      <VCol>
        <VExpansionPanels
          v-model="panel"
          class="py-3"
        >
          <VExpansionPanel>
            <VExpansionPanelHeader
              expand-icon="fal fa-chevron-down"
            >
              <h2 class="font-weight-bold">
                Новая лицензия
              </h2>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <VForm
                ref="form"
              >
                <VContainer fluid>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Приложение</strong>
                      </div>
                      <VSelect
                        v-model="entity.appName"
                        :items="apps"
                        item-text="name"
                        item-value="name"
                      />
                    </VCol>
                  </VRow>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Лицензент</strong>
                      </div>
                      <div>
                        <VAlert
                          colored-border
                          border="left"
                          type="warning"
                          elevation="1"
                        >
                          Можно выбрать только что-то <strong>ОДНО</strong>
                        </VAlert>
                      </div>

                      <VAutocomplete
                        v-model="entity.licensee.companyId"
                        clearable
                        :items="companies"
                        :filter="customFilter"
                        item-text="name"
                        item-value="id"
                        label="Компания"
                        :disabled="!!entity.licensee.accountId"
                      >
                        <template #selection="{ item }">
                          <VListItemContent>
                            <VListItemTitle v-text="item.name" />
                            <VListItemSubtitle v-text="item.id" />
                          </VListItemContent>
                        </template>

                        <template #item="{ item }">
                          <VListItemContent>
                            <VListItemTitle v-text="item.name" />
                            <VListItemSubtitle v-text="item.id" />
                          </VListItemContent>
                        </template>
                      </VAutocomplete>

                      <VAutocomplete
                        v-model="entity.licensee.accountId"
                        clearable
                        :items="accounts"
                        :filter="customFilter"
                        item-text="name"
                        item-value="id"
                        label="Аккаунт"
                        :disabled="!!entity.licensee.companyId"
                      >
                        <template #selection="{ item }">
                          <VListItemContent>
                            <VListItemTitle v-text="item.name" />
                            <VListItemSubtitle v-text="item.id" />
                          </VListItemContent>
                        </template>

                        <template #item="{ item }">
                          <VListItemContent>
                            <VListItemTitle v-text="item.name" />
                            <VListItemSubtitle v-text="item.id" />
                          </VListItemContent>
                        </template>
                      </VAutocomplete>
                    </VCol>
                  </VRow>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Длительность</strong>
                      </div>
                      <VTextField
                        v-model.number="entity.duration.days"
                        label="Дней"
                        type="tel"
                      />
                      <h3>Начало</h3>
                      <VDatePicker v-model="entity.duration.startsAt" />
                    </VCol>
                  </VRow>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Пользователи</strong>
                      </div>
                      <VTextField
                        v-model.number="entity.totalUsers"
                        label="Всего"
                        type="tel"
                      />
                      <VTextField
                        v-model.number="entity.involvedUsers"
                        label="Активные пользователи (входили хотя бы раз)"
                        type="tel"
                      />
                    </VCol>
                  </VRow>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <VCheckbox
                        v-model="entity.active"
                        label="Активный"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol md="4">
                      <VBtn
                        large
                        depressed
                        color="primary"
                        class="mr-3"
                        @click="save"
                      >
                        Создать
                      </VBtn>
                      <VBtn
                        large
                        text
                        depressed
                        @click="cancel"
                      >
                        Отмена
                      </VBtn>
                    </VCol>
                  </VRow>
                </VContainer>
              </VForm>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <VAlert
          colored-border
          border="left"
          type="warning"
          elevation="1"
        >
          Лицензии повторно активировать <strong>нельзя!!!</strong> Доступна только деактивация.
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <LicensesTable
          :loading="loading"
          :licenses="preparedLicenses"
        >
          <template #rowActions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  color="blue"
                  class="mx-1"
                  :to="{
                    name : Names.R_LICENSE,
                    params : {
                      id : item.id
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-eye
                  </VIcon>
                </VBtn>
              </template>

              <span>Просмотр</span>
            </VTooltip>

            <VTooltip
              v-if="item.active === true"
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="deactivateLicense(item)"
                >
                  <VIcon small>
                    fal fa-lock
                  </VIcon>
                </VBtn>
              </template>

              <span>Деактивировать</span>
            </VTooltip>
          </template>
        </LicensesTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import LicensesTable from '../../components/license/licenses/LicensesTable.vue';

export default {
  name: 'LicenseList',

  components: {
    LicensesTable,
  },

  inject: ['Names'],

  data() {
    return {
      entity: {
        appName: '',
        licensee: {
          accountId: '',
          companyId: '',
        },
        duration: {
          startsAt: '',
          days: 0,
        },
        totalUsers: 0,
        involvedUsers: 0,
        active: true,
      },
      accounts: [],
      apps: [],
      companies: [],
      licenses: [],
      loading: false,
      panel: undefined,
    };
  },
  computed: {
    preparedLicenses() {
      return this.licenses.map((license) => {
        const find = license.licensee.accountId
          ? this.accounts.find((account) => account.id === license.licensee.accountId)
          : this.companies.find((company) => company.id === license.licensee.companyId);
        return {
          ...license,
          licenseeName: find && find.name,
        };
      });
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    async fetch() {
      try {
        this.loading = true;
        const [
          licensesResponse,
          appsResponse,
          accountsResponse,
          companiesResponse,
        ] = await Promise.all([
          this.$di.api.License.indexLicense(),
          this.$di.api.License.indexApp(),
          this.$di.api.Account.indexAccount(),
          this.$di.api.Account.indexCompany(),
        ]);

        this.licenses = licensesResponse.licence || [];
        this.apps = appsResponse.apps || [];
        this.accounts = accountsResponse.accounts || [];
        this.companies = companiesResponse.companies || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$refs.form.reset();
    },

    save() {
      const licence = { ...this.entity };
      licence.duration.startsAt = new Date(licence.duration.startsAt).toISOString();
      this.$di.api.License.createLicense({ licence }).then(() => this.$di.api.License.indexLicense()).then((r) => {
        this.license = r.result;
        this.$di.notify.snackSuccess('Успешно создано');
      }).catch((err) => {
        this.$di.notify.errorHandler(err);
      });
    },

    async deactivateLicense(item) {
      try {
        this.loading = true;
        const data = { id: item.id };
        await this.$di.api.License.deactivateLicense(data);
        this.$di.notify.snackSuccess('Лицензия деактивирована');
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },
  },
};
</script>
